<template>
    <div class="MiscellaneousTalkPost">
        <el-page-header @back="$router.go(-1)" content="识料杂谈发帖"></el-page-header>
        <div class="breadcrumb">
            <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                    <span> 当前位置： 首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/CommunityIndex' }">社区</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/IndustryTrendsList' }">识料杂谈</el-breadcrumb-item>
                <el-breadcrumb-item>发布</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="f-cl-c postbox">
            <TextArea class="fs-18" style="width: 1100px;" :placeholderValue="'请输入文章标题（5~30个字）'" :modelValue="postHead"
                @getTextAreaValue="checkInputLength" :maxlengthValue="inputMaxLength"
                :TextAreaisLimit="true"></TextArea>
            <div id='quillEditorQiniu' style="width: 1100px;height: 550px;">
                <!-- 基于elementUi的上传组件 el-upload begin-->
                <el-upload class="avatar-uploader" action="#" :accept="'image/*,video/*'" :show-file-list="false"
                    :http-request="uploadForumOssFile">
                </el-upload>
                <!-- 基于elementUi的上传组件 el-upload end-->
                <quill-editor class="editor" style="width: 1100px;height: 550px;" v-model="content"
                    ref="customQuillEditor" :options="editorOption">
                </quill-editor>
                <span class="wordNumber" style="display: block;  width: 98%; text-align: right;">{{
            editPostContentLength }}/{{ maxPostContentLength }}</span>
            </div>
            <div class="f-c-c btn mt-80" style="width: 160px;height: 50px;border-radius: 10px;background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);color: #FFF;line-height: 50px;text-align: center;font-size: 16px;cursor: pointer;" @click="uploadPOST">发布</div>
        </div>
    </div>
</template>

<script>
//实现quill-editor编辑器拖拽上传图片
import * as Quill from 'quill'
import { ImageDrop } from 'quill-image-drop-module';
Quill.register('modules/imageDrop', ImageDrop);
//实现quill-editor编辑器调整图片尺寸
import ImageResize from 'quill-image-resize-module';
Quill.register('modules/imageResize', ImageResize);

const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
    // ['image', 'video'],
    ['image'],
];

import { UrlDecodeType } from '@/mixins/UrlDecodeMixin';
export default {
    name: 'MiscellaneousTalkPost',
    mixins: [UrlDecodeType],
    data() {
        return {
            uploadUrlPath: "没有文件上传",
            quillUpdateImg: false,
            content: '',//帖子内容 即富文本内容
            editorOption: {
                placeholder: '请输入内容',
                modules: {
                    imageResize: {
                        displayStyles: {
                            backgroundColor: 'black',
                            border: 'none',
                            color: 'white'
                        },
                        modules: ['Resize', 'DisplaySize', 'Toolbar']
                    },
                    toolbar: {
                        container: toolbarOptions,  // 工具栏
                        handlers: {
                            'image': function (value) {
                                if (value) {
                                    document.querySelector('#quillEditorQiniu .avatar-uploader input').click()
                                } else {
                                    this.quill.format('image', false);
                                }
                            },
                            // 'video': function (value) {
                            //     if (value) {
                            //         document.querySelector('#quillEditorQiniu .avatar-uploader input').click()
                            //     } else {
                            //         this.quill.format('video', false);
                            //     }
                            // },
                        }
                    }
                }
            },
            postDetail: '',
            postHead: '',//帖子标题
            inputMaxLength: 30,//标题限制最长字数
            // mobjectArray: [null, null, null, null, null, null, null, null, null],//媒体对象数组,固定长度为9,最多接收9个媒体对象
            mobjectArray: [],//媒体对象数组,固定长度为9,最多接收9个媒体对象
            imageNumber: 0,
            editPostContentLength: 0,//帖子内容的长度
            maxPostContentLength: 3000,
            IpAddressInfo: {},
            imgUrl: 'https://rmsearch-public-t.oss-cn-guangzhou.aliyuncs.com/forum_temp/2023-09/0ed6142c5d864b0fa34b39bde6bd360c.jpg',
            imgUrlList: []
        }
    },
    watch: {
        'content': {
            handler(newVal) {
                let watchContent = this.getSimpleText(newVal)
                this.editPostContentLength = watchContent.length
                if (watchContent.length >= this.maxPostContentLength) {
                    this.content = watchContent.slice(0, 3000)
                    return this.$common.message(`字数不能超过${this.maxPostContentLength}!`, 'warning', 1500)
                }
            },
            // 深度观察监听
            deep: true,
            immediate: true
        }
    },
    created() {
        this.$CommonRequests.getIpAddress().then((res) => {
            if (res.code == 200) {
                this.IpAddressInfo = res.data
            }
        })
    },
    methods: {
        // 检查标题输入的字数
        checkInputLength(data) {
            this.postHead = data.value
            this.$common.inputMaxLength(data.value, this.inputMaxLength)
        },
        // 帖子图片视频文件上传接口
        uploadForumOssFile(params) {
            let that = this;
            const formdata = new FormData();
            formdata.append("file", params.file)
            formdata.append("userId", parseInt(that.$store.state.userId))
            formdata.append("token", that.$getStorage('token'))
            that.$http.uploadForumOssFile(formdata).then(res => {
                // 临时地址
                let imgUrl = res.data;
                that.imgUrlList.push(imgUrl)
                // 类型
                let type = imgUrl.substring(imgUrl.lastIndexOf(".") + 1);
                // 获取富文本组件实例
                let quill = that.$refs.customQuillEditor.quill;
                // 获取光标所在位置
                let length = quill.getSelection().index;
                console.log(imgUrl)
                // 插入图片||视频  res.info为服务器返回的图片地址
                // if (that.mobjectArray.indexOf(null) != -1) {
                if (type == 'mp4' || type == 'MP4') {
                    quill.insertEmbed(length, 'video', imgUrl)
                } else {
                    quill.insertEmbed(length, 'image', imgUrl)
                }
                that.mobjectArray[that.imageNumber] = imgUrl
                that.imageNumber++
                // } else {
                //     that.$common.message('图片或视频一共只能上传9个！', 'warning', 2000)
                // }
                // 调整光标到最后
                quill.setSelection(length + 1)
                //取消上传动画
                this.quillUpdateImg = false;
                // that.content = that.content.replace(/src/g,":src")
                // console.log(that.content)
            })
        },
        // 获取富文本中的纯文本
        getSimpleText(html) {
            let re1 = new RegExp('<.+?>', 'g')
            let msg = html.replace(re1, '')
            msg = msg.replace('&nbsp;', '')
            return msg
        },
        // 上传帖子
        uploadPOST() {
            let that = this, { postHead, content } = that;
            if (!postHead) {
                return that.$common.message('请输入帖子标题！', 'warning', 1500)
            }
            if (!content) {
                return that.$common.message('请输入帖子内容！', 'warning', 1500)
            }
            // console.log('222',content)
            this.addForumChatArticle();
            // switch (this.queryType) {
            //     case 'ProductExchange':
            //         this.addForumArticle()
            //         break;
            //     case 'ShiLiaoZaTan':
            //         this.addForumChatArticle()
            //         break;
            // }
        },
        // 获取参数
        getParams() {
            console.log('getParams')
            let that = this, { postHead, content, mobjectArray } = that,
                interceptContent = that.getSimpleText(content).slice(0, 100),
                { ip, province, city, districts } = that.IpAddressInfo;
            // NEWcontent = '';


            // let item = 'http';
            // let reg = eval("/" + item + "/g");
            // content = content.replace(reg, `mObJ_10086_`+[1])
            // console.log(content)

            console.log('imgUrlList', that.imgUrlList)
            that.imgUrlList.forEach((item, index) => {
                // let reg = eval("/" + item.toString + "/g"),
                // name = `mObJ_10086_`+[index]
                content = content.replaceAll(item, `$IMG$` + [index]);
            })
            return {
                postUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                postContent: content,//帖子内容 即富文本内容
                postHead,//帖子标题
                mobjectArray,//图片或媒体文件数组
                previewContent: interceptContent,//内容预览 即内容截取前100个字
                postLocation: province + '*' + city + '*' + districts,//发帖来源地 即广东，韶关。。。
                sourceIp: ip,//发帖来源IP 
                sourceType: 'P'
            }
        },
        // 识料杂谈上传帖子
        addForumChatArticle() {
            let that = this;
            // console.log(that.getParams())
            that.$http.addForumChatArticle(that.getParams()).then(res => {
                if (res.code == 200) {
                    that.$common.message(res.msg, 'success')
                    that.$common.DelayedBack()
                }
            })
        },
    },
}
</script>

<style lang='less' scoped>
.MiscellaneousTalkPost {
    .breadcrumb {
        padding: 10px 0;
        border-bottom: 1px solid #FBFBFB;
        margin-bottom: 20px;
    }

    .el-breadcrumb ::v-deep .el-breadcrumb__inner {
        color: #666666 !important;
        font-weight: 400 !important;
        font-size: 14px;
    }

    .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
        color: #0363FA !important;
        font-weight: 400 !important;
        font-size: 14px;
    }

    .postbox {
        .editor {
            line-height: normal !important;
            width: 1100px !important;
            height: 550px !important;
            margin-bottom: 80px;
        }

        .wordNumber {
            display: block;
            width: 98%;
            text-align: right;
        }

        .Btn {
            width: 160px;
            height: 50px;
            border-radius: 10px;
            background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
            color: #FFF;
            line-height: 50px;
            text-align: center;
            font-size: 16px;
            cursor: pointer;
        }
    }

    /* .editor {
        line-height: normal !important;
        width: 1100px !important;
        height: 550px !important;
        margin-bottom: 80px;
    }

    .wordNumber {
        display: block;
        width: 98%;
        text-align: right;
    } */
}
</style>
